import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/apps/landing/src/app/[locale]/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/vercel/path0/apps/landing/src/app/[locale]/nav-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/vercel/path0/apps/landing/src/i18n/client/i18n-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/landing/src/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/vercel/path0/node_modules/motion/dist/es/motion/lib/react.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/sans.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/packages/ui/src/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem","DropdownMenuSeparator","DropdownMenuLabel"] */ "/vercel/path0/packages/ui/src/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Icon"] */ "/vercel/path0/packages/ui/src/icon.tsx");
